:root {
  --main-bg-color: #000000;
  --main-text-color: #000000;
  --background-color: #ffffff;
  --hover-color: #000000;
}

html {
  height: 100%;
  box-sizing: border-box;
  background-color: var( --main-bg-color);
  color: var(--main-text-color);
  background-color: var(--background-color);
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%);
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  width: 100%;
  margin: 0;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
}


footer {
  margin: 0;
  padding: 1em 0;
  align-items: center;

}

nav {
  margin: 0;
  padding: 1.5em 0;
}

header {
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;

}

.guide{
  display: flex;
  flex-direction: row;
}

.scrumb {
  padding-left: 2rem;
  color: var(--main-text-color);

}

.guide a .scrumb{
  transition: ease-in-out .3s;
}

.guide a .scrumb:hover{
  color: var(--hover-color);
  font-weight: 400;
}

.headergrid {
  align-items: center;
  display: grid;
  grid-template-columns: [col1] 25%  [col2] 50%  [col3] 25% [col4];
  padding: 1em;
}

.cola {
  grid-column: col1/col2;
  margin: 0 auto;
}
.colb {
  grid-column: col2/col3;
  margin: 0 auto;
}
.colc {
  grid-column: col3/col4;
  width: 40%;
  border-radius: 1rem;
  margin: 0 auto;
}

h1 {
  color: var(--main-bg-color);
}

h1:hover {
  color: var(--hover-color);
  transition: .5s ease;
  font-weight: 400;
}

h1,
header h2 {
  margin: 0;
}

.carreras {
  font-size: 1.7rem;
  font-weight: bold;
}

.carreras span{
  font-size: 1.7rem;
}

.contenedor2 {
  max-width: 90em;
  margin: 0 auto;
  padding: 0 1rem;
  /* border-bottom: 1px solid var(--main-text-color);   */
}
* {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: var(--main-text-color);
}

a {
  text-decoration: none;
}

nav a {
  color: var(--main-bg-color);
}

.encabezado {
  color: var(--main-text-color);
  width: 95%;
  margin: 1em auto;
}

nav:hover {
  color: var(--hover-color);
  transition: .5s ease;
  font-weight: 400;
}

a:hover, nav {
  color: var(--hover-color);
  transition: .5s ease;
  font-weight: 400;
}


footer h3, footer a {
  color: var(--main-bg-color);
}

.footer a:hover {
  color: var(--hover-color);
  font-weight: 400;
}

.datum span, .datum h3 {
  margin: 0;
}

.icon-section span, .epigraph {
  color: black;
  font-weight: bold;
  margin-top: 0.5em;
}

main h1 {
  color: var(--main-text-color);
  font-size: 1.5em;
}

.seeon-box {
  font-size: 2em;

}

.about em {
  font-style: normal;
}

.seeon-box span {
  color: inherit;
}

.padding-top2em {
  padding-top: 2em;
}
.margin-top2em {
  margin-top: 2em;
}

.iconize {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  padding: 0rem;
}

.icon-section {
  box-shadow: 0 0 0.3em grey;
  /* border: 1px solid var(--main-text-color); */
  border-radius: 1rem;
  padding: 1rem;
}

.icon-section span,
.epigraph {
  text-align: center;
}

.data {
  display: flex;
  justify-content: space-between;
}

.titulo {
  color:var(--main-bg-color);
  margin: 0 auto;
  margin-bottom: 1em;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
}


.boton {
  display: block;
  filter: grayscale(1);
  border-radius: 1rem;
  box-shadow: 0 0 0.3em grey;
  
  /* border: solid 1px var(--main-text-color); */
}

.listado {
  display: block;
  filter: grayscale(1);
}

.imagen2 {
  width: 100%;
  text-align: center;

  border-radius: 1rem;
}

.legenda {

  color: var(--main-bg-color);
  font-family: 'Open sans', sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  padding: 0 0 1rem 0;
  bottom: 1em;

}

.legenda span {
  color: var(--main-text-color);
  font-weight: bold;
}

.video {
  border-radius: 2rem;
  width: 95%;
  margin-bottom: 1rem;
}

.imagen1 {
  width: 95%;
  text-align: center;
  margin-bottom: 0.5em;
  border-radius: 1rem;
}

.boton:hover {
  transform: scale(1.01, 1.01);
  filter: grayscale(0);
  transition: .1s ease-in-out;
}


footer, .section-background {
  width: 100%;
  align-items: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
}

.section-background {
  display: flex;
}


.datum {
  display: inline-block;
  display: flex;
  text-align: center;
  padding: 0 2.5%;
  flex-flow: column;
  margin: 1em 0;
}

.icono {
  width: 1.5em;
  color: var(--main-text-color);
  padding: 0;
}

.flag {
  border-radius: 0.5rem;
}

.motion {
  display: inline-block;
  font-weight: bold;
  width: 9em;
  text-align: center;
}

.icon-section img {
  max-width: 100%;
  margin: auto;
}

.me {
  width: 100%;
}

.flag {
  height: auto;
}

.seeon-box:hover {
  background-image: linear-gradient(135deg, var(--hover-color), #0057ff);
  color: white;
}

.copete {
  color: var(--main-bg-color);
  font-size: 1em;
  text-align: center;
  padding: 0 1em;
}

@media only screen and (max-width: 60em) {

  nav {
    padding: 0.5em;
  }

  .headergrid {
    align-items: center;
    display: inline;
    grid-gap: 1rem;
    border-bottom: none;
    padding: 2em 0;
  }

  .contenedor2 {
    padding: 0;
    margin-top: 1em;
  }

  .boton {
    margin-bottom: 0.5em;
  }

  .guide{
    display: flex;
    flex-direction: column;
    margin: 1rem ;
  }

  .scrumb {
    margin: 0;
    padding: 0;
  }

  .gualchicnc {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 95%;
    margin: 0 auto;
  }

  .colc {
    margin: 2rem 0;
  }

  header {
    margin-bottom: 0;
  }

  header,
  main,
  footer {
      width: 100%;
      text-align: center;
  }

  .data {
      display: block;
  }


  main h1 {
      text-align: center;
      margin-bottom: 1em;
  }

  .imagen1, .legenda {
      width: 95%;
  }

  .imagen2 {
    margin-bottom: 0;
  }
  .titulo {
    margin-top: 1em;
  }

  .legenda {
    left: 2.5%;
  }

  .boton {
      filter: grayscale(0);
  }

  .boton:hover {
      transform: none;
      transition: none
  }
  .motion {
      display: flex;
      flex-flow: column;
      margin: 1em;
  }
  .datum {
      padding: 0.5em 0;
      font-size: 0.8em;
  }

  .data {
      display: block;
  }

  main h1 {
      text-align: center;
      margin-bottom: 1em;
  }

  .encabezado {
    width: 90%;
    margin: 1em auto;
  }

}

.app-descripcion {
  margin-bottom: 2rem;
  color: var(--main-text-color);
  text-align: center;
}

